/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, LoadingButton } from "@enerbit/base";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { schemaOrder } from "../../../helpers";
import { useResetStore } from "../../../hooks/useResetStore";
import { ItemGetOrders } from "../../../models";
import { IPostOrders } from "../../../models/IPostOrders";
import { editOrder, useAppSelector } from "../../../store";
import { useAppDispatch } from "../../../store/store";
import { OrderCancellationReason } from "../cancellation-reason";
import { InfoOrder } from "../info-order";
import { LeadPartnerContent } from "../lead-partner";
import Notifications from "../nofitifications/Notifications";
import { Observations } from "../observations";

export const EditProvider = () => {
	const { enqueueSnackbar } = useSnackbar();
	const {
		selectedOrder,
		addedEntities,
		listEntities,
		entity,
		isDone,
		loadingOrder,
		errorOrder,
		activity,
		action,
	} = useAppSelector((state) => state.initialForm);
	const { detailsOrder } = useAppSelector((state) => state.detailProgress);
	const dispatch = useAppDispatch();

	const [initialValues, setInitialValues] = useState<ItemGetOrders | null>(
		null,
	);

	useEffect(() => {
		setInitialValues(selectedOrder ?? null);
	}, [selectedOrder]);

	const methods = useForm<IPostOrders>({
		resolver: yupResolver(schemaOrder),
		mode: "onChange",
	});

	const { resetFormContent } = useResetStore();

	const {
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = methods;
	console.log(errors);
	useEffect(() => {
		setInitialValues(selectedOrder ?? null);
		reset(selectedOrder);
	}, [selectedOrder]);

	const getChangedValues = (currentValues: IPostOrders) => {
		const changes: Partial<IPostOrders> = {};
		if (!initialValues) return changes;

		const checkChanges = (objA: any, objB: any, parentKey = "") => {
			Object.keys(objA || {}).forEach((key) => {
				const fullKey = parentKey ? `${parentKey}.${key}` : key;

				if (_.isArray(objA[key])) return; // Ignora si el valor actual es un array.

				if (_.isEqual(objA[key], objB[key])) return;
				if (typeof objA[key] === "object" && objA[key] !== null) {
					checkChanges(objA[key], objB[key], fullKey);
				} else {
					_.set(changes, fullKey, objB[key]);
				}
			});
		};

		checkChanges(initialValues, currentValues);
		return changes;
	};

	const onSubmit = async (formData: IPostOrders) => {
		//const changes = getChangedValues(formData);
		if (Object.keys(formData).length === 0) {
			enqueueSnackbar("No se realizaron cambios.", { variant: "warning" });
			return;
		}
		dispatch(editOrder({ id: selectedOrder?.id || "", order: formData }));
	};

	useEffect(() => {
		if (addedEntities.length > 0) {
			addedEntities.map(({ id }, index) => {
				setValue(`order_entities.${index}.assignee_id`, id);
				setValue(
					`order_entities.${index}.entity_id`,
					listEntities.find(({ code }) => code === entity)?.id || "",
				);
			});
		}
	}, [addedEntities]);

	useEffect(() => {
		if (errorOrder) {
			enqueueSnackbar(
				"No se pudo guardar los cambios, por favor intente nuevamente.",
				{
					variant: "error",
				},
			);
		}
	}, [errorOrder]);

	useEffect(() => {
		if (isDone) {
			enqueueSnackbar("Los cambios fueron guardados con éxito.", {
				variant: "success",
			});
			resetFormContent();
		}
	}, [isDone]);

	return (
		<FormProvider {...methods}>
			<Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<Grid container rowSpacing={3}>
					<LeadPartnerContent />
					{(action?.activity?.code === "CC" ||
						action?.activity?.code === "REMOV_CE_NO_INST") && (
						<OrderCancellationReason />
					)}
					<InfoOrder />
					<Observations />
					<Notifications />
					<Grid item xs={12} display="grid">
						<LoadingButton
							disabled={selectedOrder?.status !== "pending" && detailsOrder}
							id="edit-order"
							loading={loadingOrder}
							type="submit"
							color="secondary"
							variant="contained"
						>
							Guardar cambios
						</LoadingButton>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	);
};
